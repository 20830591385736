<template>
  <div class="container">
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card shadow="hover" class="mgb20" style="height: 195px">
          <div class="user-info">
            <img :src="image" class="user-avator" alt/>
            <div class="user-info-cont">
              <div class="user-info-name">{{ name }}</div>
            </div>
          </div>
        </el-card>
        <el-card shadow="hover" style="height: 150px">
          <template #header>
            <div class="clearfix">
              <span>待阅留言：</span><el-button type="text" class="messageWatch" @click="dialogShowAllMessageVisibleFun">查看全部留言</el-button>
            </div>
          </template>
          <ul v-loading="messageloading">
            <li class="messageToBeRead" v-for="message in messageNotReadedList" :key="message.id">
              <p class="messageToBeRead_content" >{{message.content}}</p>
              <span style="float:right;">{{ new Date(message.created_at).toLocaleDateString() }}</span>
            </li>
          </ul>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-row :gutter="10" class="mgb20">
          <el-col :span="8">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-1">
                <i class="el-icon-s-comment grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{ totalbookscount }}</div>
                  <div>总访问量</div>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-2">
                <i class="el-icon-s-comment grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{ totalborrow }}</div>
                  <div>本月访问量</div>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-3">
                <i class="el-icon-s-comment grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{ totalreturn }}</div>
                  <div>本周访问量</div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-card shadow="hover">
          访问趋势
          <schart
              ref="line"
              class="schart"
              canvasId="trends"
              :options="options"
              v-loading="trendload"
          ></schart>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog title="留言" width="50%" v-model="dialogShowAllMessageVisible">
      <el-tabs tab-position="left">
        <el-tab-pane label="待阅留言">
          <el-card class="box-card" shadow="hover" v-for="message in messageNotReadedList" :key="message.id">
            <el-button style="float: right;margin-bottom: 20px;" type="primary" @click="hasRead(message.strID)">已阅</el-button>
            <div style="width: 83%;font-size: 14px;color: #6b6b6b;line-height: 20px">
              {{ message.content}}
              <p style="font-size: 13px;text-align: right;margin-top: 5px">名称:{{ message.name }}&nbsp;&nbsp;&nbsp;联系方式:{{ message.contact }}&nbsp;&nbsp;&nbsp;日期:{{new Date(message.created_at).toLocaleDateString()}}</p>
            </div>
          </el-card>
          <div style="margin-top:20px;text-align:right;">
            <el-pagination
                background
                :total="noreadedtotal"
                :current-page="noreadedlistQuery.pageNum"
                :page-size="noreadedlistQuery.pageCount"
                layout="prev,pager,next,jumper,total"
                @current-change="currPageNotReadedChange"/>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已阅留言">
          <el-card class="box-card" shadow="hover" v-for="message in messageReadedList" :key="message.id">
<!--            <el-button style="float: right;margin-bottom: 20px;" type="primary">已阅</el-button>-->
            <div style="font-size: 14px;color: #6b6b6b;line-height: 20px">
              {{ message.content}}
              <p style="font-size: 13px;text-align: right;margin-top: 5px">名称:{{ message.name }}&nbsp;&nbsp;&nbsp;联系方式:{{ message.contact }}&nbsp;&nbsp;&nbsp;日期:{{new Date(message.created_at).toLocaleDateString()}}</p>
            </div>
          </el-card>
          <div style="margin-top:20px;text-align:right;">
            <el-pagination
                background
                :total="hasreadedtotal"
                :current-page="hasreadedlistQuery.pageNum"
                :page-size="hasreadedlistQuery.pageCount"
                layout="prev,pager,next,jumper,total"
                @current-change="currPageHasReadedChange"/>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import Schart from "vue-schart";
import * as echarts from "echarts";
export default {
  name: "homeManager",
  data() {
    return {
      dialogShowAllMessageVisible: false,
      image: 1,
      allVisit: 0,
      monthVisit: 0,
      weekVisit: 0,
      trendload: false,
      messageNotReadedList: [],
      messageReadedList: [],
      noreadedtotal: 0,
      noreadedlistQuery:{
        "pageCount": 6,
        "pageNum": 1,
        Search:{
          IsReading: false
        }
      },
      hasreadedtotal: 0,
      hasreadedlistQuery:{
        "pageCount": 6,
        "pageNum": 1,
        Search:{
          IsReading: true
        }
      },
      messageloading: false,
      messagereadedloading: false,
      //访问趋势
      options: {
        type: "line",
        title: {
          text: "访问趋势",
        },
        labels: ["暂无"],
        datasets: [
          {
            label: "暂无",
            data: [0],
            fillColor: "rgb(107,160,236)",
          },
        ],
      },
    };
  },
  components: {
    Schart,
  },
  mounted() {
  },
  watch: {

  },
  computed: {},
  created() {
    this.getMessage()
    this.getReadingMessage()
  },
  methods: {
    dialogShowAllMessageVisibleFun(){
      this.dialogShowAllMessageVisible = true
    },
    getMessage(){
      this.messageloading = true
      this.$axios({
        url: this.localpath + 'officialweb/listStayNote',
        method: 'post',
        data: JSON.stringify(this.noreadedlistQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.code === 0) {
          this.noreadedtotal = res.data.msg.total
          this.messageNotReadedList = res.data.msg.notes
        }
        this.messageloading = false
      })
    },
    getReadingMessage(){
      // this.messagereadedloading = true
      this.$axios({
        url: this.localpath + 'officialweb/listStayNote',
        method: 'post',
        data: JSON.stringify(this.hasreadedlistQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.code === 0) {
          this.hasreadedtotal = res.data.msg.total
          this.messageReadedList = res.data.msg.notes
        }
        // this.messagereadedloading = false
      })
    },
    hasRead(id){
      this.$axios({
        url: this.localpath + 'officialweb/readStayNote',
        method: 'post',
        data: JSON.stringify({"strId": id}),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.code === 0) {
          // this.messageNotReadedList = res.data.msg.notes
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.getMessage()
          this.getReadingMessage()
        }
      })
    },
    currPageHasReadedChange(pageNum){
      if(pageNum!==null) {
        this.hasreadedlistQuery.pageNum = pageNum
        this.getReadingMessage()
      }
    },
    currPageNotReadedChange(pageNum){
      if(pageNum!==null) {
        this.noreadedlistQuery.pageNum = pageNum
        this.getMessage()
      }
    },
  },
};
</script>

<style scoped>
.box-card{
  margin:10px 10px;
}
.messageToBeRead {
  font-size: 14px;
  line-height: 27px;
  height: 27px;
  color: #999999;
  list-style:none;
  margin-left: 5px;
}

.messageToBeRead_content {
  width: 85%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.messageWatch{
  color: #4481ff;
  float: right;
  font-size: 14px;
  padding: 3px !important;
  min-height: 10px !important;
}
.messageWatch:hover{
  color: #1258e7;
}

.grid-content {
  display: flex;
  align-items: center;
  height: 100px;
}

.grid-cont-right {
  flex: 1;
  text-align: center;
  font-size: 14px;
  color: #999;
}

.grid-num {
  font-size: 30px;
  font-weight: bold;
}

.grid-con-icon {
  font-size: 50px;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  color: #fff;
}

.grid-con-1 .grid-con-icon {
  background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
  background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
  background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
  color: rgb(242, 94, 67);
}

.user-info {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
}

.user-avator {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.user-info-cont {
  padding-left: 50px;
  flex: 1;
  font-size: 14px;
  color: #999;
}

.user-info-cont div:first-child {
  font-size: 30px;
  color: #222;
}


.user-info-list span {
  margin-left: 70px;
}

.mgb20 {
  margin-bottom: 20px;
}


.schart {
  width: 100%;
  height: 300px;
}
</style>
